import { getTrackByGuidAsync } from "@bu/lib-services";
import { useQuery } from "@tanstack/react-query";

// TODO: Temp name until guid refactor is complete.
export const useTrackGuidQuery = (trackGuid: string) => {
  const query = useQuery({
    queryKey: ["track", trackGuid],
    queryFn: () => getTrackByGuidAsync(trackGuid),
    enabled: true,
  });

  return {
    track: query.data,
    isLoading: query.isLoading,
    isError: query.isError,
  };
};
