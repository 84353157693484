import { DeleteSubscriptionApiResponse_v1 } from "@bu/lib-types";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const deleteSubscriptionAsync = async (subscriptionGuid: string) => {
  const client = await apiClientFactory.buildWithAuthAsync(ApiTypes.Identity, [
    "tasks.read",
  ]);
  const config: AxiosRequestConfig = {
    method: "DELETE",
    url: `/api/v1/subscription/${subscriptionGuid}`,
  };

  return client
    .request<DeleteSubscriptionApiResponse_v1>(config)
    .then((res) => res.data);
  //.then((data) => {
  //  // Check for errors.
  //  if (!data.isSuccess) {
  //     console.error("DeleteSubscriptionAsync error", data.errors);
  //     throw new Error("Error deleting subscription.");
  //   }
  //  // Return payload.
  //  // TODO: This doesn't have an empty payload yet, but when it does, I will wonder why.
  //  return data.payload;
  //});
};
