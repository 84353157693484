import { getPersonalStoriesAsync } from "@bu/lib-services";
import { GetPersonalStoriesPayload } from "@bu/lib-types";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useMobilePersonalStoryQuery = (
  payload: Omit<GetPersonalStoriesPayload, "page"> & { page?: number }
) => {
  const { albumTracksLimit, page } = payload;

  const query = useInfiniteQuery({
    queryKey: ["getPersonalStories"],
    queryFn: ({ pageParam = 0 }) => {
      return getPersonalStoriesAsync({
        page: page || pageParam,
        albumTracksLimit,
      });
    },
    getNextPageParam: (lastPage) => lastPage.payload.page + 1,
    initialPageParam: page,
  });

  return {
    pages: query.data?.pages,
    stories: query.data?.pages.flatMap((p) => p.payload.data),
    lastResponse: query.data?.pages[query.data.pages.length - 1],
    isLoadingStories: query.isLoading,
    refetchStories: query.refetch,
    fetchNextPage: query.fetchNextPage,
  };
};
