import { getTracksByGuidListAsync } from "@bu/lib-services";
import { TrackDto_v1 } from "@bu/lib-types";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useTrackListQuery = (trackGuidList: string[]) => {
  const queryClient = useQueryClient();

  const query = useQuery<TrackDto_v1[]>({
    queryKey: ["trackList"],
    queryFn: () => {
      // Filter out any tracks that are already in the cache.
      const newGuids = trackGuidList.filter(
        (guid) => !queryClient.getQueryData(["track", guid])
      );
      // Fetch the new tracks and cache them.
      return getTracksByGuidListAsync(newGuids).then((tracks) => {
        tracks.forEach((track) => {
          queryClient.setQueryData(["track", track.guid], track);
        });
        return tracks;
      });
    },
    enabled: trackGuidList.length > 0,
    staleTime: 0,
  });

  return {
    tracks: query.data,
    isLoading: query.isLoading,
    isError: query.isError,
  };
};
