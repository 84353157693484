import { CssBaseline, Theme } from "@mui/material";
import { ThemeProvider as MUIThemeProvider } from "@mui/material/styles";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { themeDark, themeLight } from "../themes";

interface ThemeContextType {
  theme: Theme;
  themeName: string;
  toggleTheme: () => void;
}

export const ThemeContext = createContext<ThemeContextType>(
  {} as ThemeContextType
);

interface ThemeProviderProps extends PropsWithChildren {}

export const ThemeProvider = (props: ThemeProviderProps) => {
  const { children } = props;

  // Read the theme from localStorage or set a default theme
  const [themeName, setThemeName] = useState(
    localStorage.getItem("themeName") || "light"
  );
  const theme = themeName === "light" ? themeLight : themeDark;

  useEffect(() => {
    localStorage.setItem("themeName", themeName);
  }, [themeName]);

  // Toggle the theme and save it to localStorage.
  const toggleTheme = () => {
    setThemeName((prevThemeName: string) =>
      prevThemeName === "light" ? "dark" : "light"
    );
  };

  // Check if the context is already loaded.
  const selfContext = useContext(ThemeContext);
  if (selfContext.themeName) {
    return <>{children}</>;
  }

  return (
    <ThemeContext.Provider value={{ theme, themeName, toggleTheme }}>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
};
