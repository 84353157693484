import { useIsAuthenticated } from "@azure/msal-react";
import { getSubscriptionsAsync } from "@bu/lib-services";
import { useQuery } from "@tanstack/react-query";

export const useSubscriptionListQuery = () => {
  const isAuthenticated = useIsAuthenticated();
  const queryKey = ["subscriptionList"];

  const query = useQuery({
    enabled: isAuthenticated,
    queryFn: () => getSubscriptionsAsync(),
    queryKey: queryKey,
  });

  return {
    subscriptionList: query.data,

    isSubscriptionListLoading: query.isLoading,
    isSubscriptionListError: query.isError,
  };
};
