import {
  deleteReleaseByGuidAsync,
  getReleasesAsync,
  insertReleaseAsync,
} from "@bu/lib-services";
import { BaseReleaseDto_v1, ReleaseDto_v1 } from "@bu/lib-types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const useProfileReleasesQuery = (profileGuid: string) => {
  const queryClient = useQueryClient();
  const queryKey = ["profileReleases", profileGuid];

  const query = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      return getReleasesAsync().then((releases) => {
        releases.forEach((release) => {
          queryClient.setQueryData(["release", release.releaseGuid], release);
        });
        return releases;
      });
    },
    staleTime: 0,
  });

  const insertMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (baseRelease: BaseReleaseDto_v1) =>
      insertReleaseAsync(baseRelease),
    onSuccess: () => {
      query.refetch();
    },
  });

  const deleteMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (trackGuid: string) => deleteReleaseByGuidAsync(trackGuid),
    onSuccess: (_, deleteGuid) => {
      queryClient.setQueryData<ReleaseDto_v1[]>(queryKey, (oldReleases) => {
        return oldReleases?.filter(
          (oldRelease: ReleaseDto_v1) => oldRelease.releaseGuid !== deleteGuid
        );
      });
    },
  });

  return {
    releases: query.data,

    insertReleaseAsync: insertMutation.mutateAsync,
    deleteReleaseAsync: deleteMutation.mutateAsync,

    isReleaseLoading: query.isLoading,
    isReleaseError: query.isError,
  };
};
