import { useIsAuthenticated } from "@azure/msal-react";
import {
  deleteSubscriptionAsync,
  getSubscriptionByGuidAsync,
  insertSubscriptionAsync,
  updateSubscriptionAsync,
} from "@bu/lib-services";
import { BaseSubscriptionDto_v1, SubscriptionDto_v1 } from "@bu/lib-types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export const useSubscriptionQuery = (subscriptionGuid?: string) => {
  const [_subscriptionGuid, _setSubscriptionGuid] = useState(subscriptionGuid);
  const queryKey = ["subscription", _subscriptionGuid];
  const isAuthenticated = useIsAuthenticated();
  const queryClient = useQueryClient();

  // Updates internal GUID state if the GUID changes.
  useEffect(() => {
    if (_subscriptionGuid === subscriptionGuid) return;
    _setSubscriptionGuid(subscriptionGuid);
  }, [subscriptionGuid, _subscriptionGuid]);

  const query = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      if (!_subscriptionGuid)
        throw new Error("BaseSubscription must be inserted first.");
      return getSubscriptionByGuidAsync(_subscriptionGuid);
    },
    enabled: isAuthenticated,
  });

  const insertMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (baseSubscription: BaseSubscriptionDto_v1) => {
      if (_subscriptionGuid)
        throw new Error("Subscription cannot be inserted again.");
      return insertSubscriptionAsync(baseSubscription);
    },
    onSuccess: (newSubscriptionGuid) => {
      _setSubscriptionGuid(newSubscriptionGuid);
    },
  });

  const updateMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (subscription: SubscriptionDto_v1) => {
      if (!_subscriptionGuid)
        throw new Error("Subscription must be inserted first.");
      return updateSubscriptionAsync(subscription);
    },
  });

  const deleteMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: () => {
      if (!_subscriptionGuid)
        throw new Error("Subscription must be inserted first.");
      return deleteSubscriptionAsync(_subscriptionGuid);
    },
    onSuccess: () => {
      _setSubscriptionGuid(undefined);
      queryClient.setQueryData(queryKey, undefined);
    },
  });

  return {
    subscription: query.data,

    insertSubscriptionAsync: insertMutation.mutateAsync,
    updateSubscriptionAsync: updateMutation.mutateAsync,
    deleteSubscriptionAsync: deleteMutation.mutateAsync,

    isSubscriptionLoading: query.isLoading,
    isSubscriptionError: query.isError,
  };
};
