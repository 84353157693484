import { lens, Lens } from "@dhmk/zustand-lens";
import { Store } from "../../store";

export interface AlertSlice {
  isOpen: boolean | undefined;
  message: string;

  setIsOpen: (status: boolean) => void;
  setMessage: (message: string, status: boolean | undefined) => void;
}

const alertSlice: Lens<AlertSlice, Store> = (set, get, api) => ({
  isOpen: false,
  message: "",
  setIsOpen: (status: boolean) => {
    set((state) => {
      state.isOpen = status;
    });
  },
  setMessage: (message: string, status: boolean | undefined) => {
    set((state) => {
      state.message = message;
      state.isOpen = status;
    });
  },
});

export const alertLens = lens(alertSlice);
