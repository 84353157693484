import {
  deleteTrackAsync,
  getTracksByReleaseGuidAsync,
  insertTrackAsync,
  updateTrackAsync,
} from "@bu/lib-services";
import { BaseTrackDto_v1 } from "@bu/lib-types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const useReleaseTracksQuery = (releaseGuid: string) => {
  const queryKey = ["releaseTracks", releaseGuid];
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      return getTracksByReleaseGuidAsync(releaseGuid).then((tracks) => {
        tracks.forEach((track) => {
          queryClient.setQueryData(["track", track.guid], track);
        });
        return tracks;
      });
    },
    staleTime: 0,
  });

  const insertMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (baseTrack: BaseTrackDto_v1) =>
      insertTrackAsync(releaseGuid, baseTrack),
    onSuccess: () => {
      query.refetch();
    },
  });

  const updateMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: updateTrackAsync,
  });

  const deleteMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (trackGuid: string) => deleteTrackAsync(trackGuid),
    onSuccess: () => {
      query.refetch();
    },
  });

  return {
    tracks: query.data,

    insertTrackAsync: insertMutation.mutateAsync,
    updateTrackAsync: updateMutation.mutateAsync,
    deleteTrackAsync: deleteMutation.mutateAsync,

    isTrackLoading: query.isLoading,
    isTrackError: query.isError,
  };
};
