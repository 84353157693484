/**
 * Figma Palette.
 * https://www.figma.com/file/DxuztEqEo6FfEWLfoHpjzN/Beats-Union?type=design&node-id=4-15682
 */
export const palette = {
  // Blues.
  B100: "#F2F6FF",
  B200: "#CFDCFF",
  B300: "#ACC2FF",
  B400: "#89A8FF",
  B500: "#668EFF",
  B600: "#4374FF", // Primary
  B700: "#365DCD",
  B800: "#29469B",
  B900: "#1B2F68",
  B1000: "#0E1836",

  // Greens.
  G100: "#F2F7F5",
  G200: "#C2F1D6",
  G300: "#91EBB7",
  G400: "#61E598",
  G500: "#00D95A",
  G600: "#00D95A",
  G700: "#00AD48",
  G800: "#008135",
  G900: "#005523",
  G1000: "#002910",

  // Pinks.
  P100: "#FFF5F8",
  P200: "#FFCDDC",
  P300: "#FFA4C1",
  P400: "#FF7CA5",
  P500: "#FF538A",
  P600: "#FF2B6E",
  P700: "#C72256",
  P800: "#8F183E",
  P900: "#570F25",
  P1000: "#1F050D",

  // Yellows.
  Y100: "#FFFDFA",
  Y200: "#FFEBD0",
  Y300: "#FFD9A5",
  Y400: "#FFC77B",
  Y500: "#FFB550",
  Y600: "#FFA326",
  Y700: "#C8801D",
  Y800: "#925D15",
  Y900: "#5B390D",
  Y1000: "#241604",

  // Oranges.
  O100: "#FCF9F7",
  O200: "#FCD7CD",
  O300: "#FCB5A4",
  O400: "#FC937A",
  O500: "#FC7151",
  O600: "#FC4F27",
  O700: "#CF7D1C",
  O800: "#9E5613",
  O900: "#6E300A",
  O1000: "#3D0A00",

  // Slate Greys.
  SG100: "#F8FAFC",
  SG200: "#F2F5FA",
  SG300: "#E2E9F0",
  SG400: "#CCD6E1",
  SG500: "#95A4B7",
  SG600: "#65758A",
  SG700: "#485668",
  SG800: "#344254",
  SG900: "#1E2A3B",
  SG1000: "#0F182A",

  // Zinc Greys.
  ZG100: "#FAFAFA",
  ZG200: "#F4F4F5",
  ZG300: "#E4E4E7",
  ZG400: "#D4D4D8",
  ZG500: "#A1A1AA",
  ZG600: "#71717A",
  ZG700: "#52525B",
  ZG800: "#3F3F46",
  ZG900: "#27272A",
  ZG1000: "#18181B",

  // Black.
  BL100: "#000000",

  // White.
  WH100: "#FFFFFF",
};
