import {
  TrackDto_v1,
  UpdateTrackApiResponse_v1,
  UpdateTrackRequest_v1,
} from "@bu/lib-types";
import { AxiosRequestConfig } from "axios";
import { apiClientFactory, ApiTypes } from "../../apiClientFactory";

export const updateTrackAsync = async (track: TrackDto_v1) => {
  const client = await apiClientFactory.buildWithAuthAsync(ApiTypes.Music, [
    "tasks.read",
  ]);
  const config: AxiosRequestConfig<UpdateTrackRequest_v1> = {
    data: {
      track,
    },
    method: "PATCH",
    url: `/api/v1/track`,
  };

  return client
    .request<UpdateTrackApiResponse_v1>(config)
    .then((response) => response.data);
};
