import { useIsAuthenticated } from "@azure/msal-react";
import {
  deleteProfileAsync,
  getProfilesAsync,
  insertProfileAsync,
  updateProfileAsync,
} from "@bu/lib-services";
import {
  ProfileDto_v1,
  RoleDto_v1,
  SubscriptionDto_v1,
  UserDto_v1,
} from "@bu/lib-types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const useUserProfileQuery = () => {
  const queryKey = ["userProfiles"];
  const isAuthenticated = useIsAuthenticated();
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      return getProfilesAsync().then((profiles) => {
        profiles.forEach((profile) => {
          queryClient.setQueryData(["profile", profile.guid], profile);
        });
        return profiles;
      });
    },
    enabled: isAuthenticated,
    staleTime: 0,
  });

  const insertMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (mutationArgs: {
      user: UserDto_v1;
      role: RoleDto_v1;
      subscription: SubscriptionDto_v1;
      profile: ProfileDto_v1;
    }) =>
      insertProfileAsync(
        mutationArgs.user,
        mutationArgs.role,
        mutationArgs.subscription,
        mutationArgs.profile
      ),
  });

  const updateMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: updateProfileAsync,
  });

  const deleteMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (mutationArgs: { profile: ProfileDto_v1; role: RoleDto_v1 }) =>
      deleteProfileAsync(mutationArgs.profile, mutationArgs.role),
  });

  return {
    profiles: query.data || [],

    insertProfileAsync: insertMutation.mutateAsync,
    updateProfileAsync: updateMutation.mutateAsync,
    deleteProfileAsync: deleteMutation.mutateAsync,

    isProfileLoading: query.isLoading,
  };
};
