import { getTracksWithFiltersAsync } from "@bu/lib-services";
import { GetTracksWithFiltersPayload } from "@bu/lib-types";
import { useInfiniteQuery } from "@tanstack/react-query";

export type UseMobileTrackQueryParam = Omit<
  GetTracksWithFiltersPayload,
  "page"
> & { page?: number };

export const useMobileTrackQuery = (payload: UseMobileTrackQueryParam) => {
  const { type, filters, similarTo, page } = payload;

  const query = useInfiniteQuery({
    queryKey: ["getTracksWithFilters", filters, similarTo],
    queryFn: ({ pageParam = 0 }) => {
      return getTracksWithFiltersAsync({
        type,
        filters,
        similarTo,
        page: page || pageParam,
      });
    },
    getNextPageParam: (lastPage) => lastPage.payload.page + 1,
    initialPageParam: page,
  });

  return {
    pages: query.data?.pages,
    tracks: query.data?.pages.flatMap((p) => p.payload.data),
    lastResponse: query.data?.pages[query.data.pages.length - 1],
    isLoadingTracks: query.isLoading,
    refetchTracks: query.refetch,
    fetchNextPage: query.fetchNextPage,
  };
};
