import {
  GetTracksWithFiltersPayload,
  GetTracksWithFiltersResponse,
} from "@bu/lib-types";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const getTracksWithFiltersAsync = async (
  payload: GetTracksWithFiltersPayload
) => {
  const client = await apiClientFactory.buildAsync(ApiTypes.Mobile);

  const urlParams = new URLSearchParams();
  urlParams.append("type", payload.type.toString());
  payload.filters.forEach((filter) => {
    urlParams.append("filters", filter.toString());
  });
  urlParams.append("similarTo", payload.similarTo || "undefined");
  urlParams.append("page", payload.page.toString());

  const config: AxiosRequestConfig = {
    method: "GET",
    url: `/api/v1/tracks?${urlParams.toString()}`,
  };

  return client
    .request<GetTracksWithFiltersResponse>(config)
    .then((response) => {
      return response.data;
    });
};

