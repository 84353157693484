import { PublicClientApplication } from "@azure/msal-browser";
import { getMsalConfig } from "../msal";

export class MsalSingleton extends PublicClientApplication {
  private static instance: MsalSingleton;

  private constructor() {
    super(getMsalConfig());
  }

  public static getInstance(): MsalSingleton {
    if (!MsalSingleton.instance) {
      MsalSingleton.instance = new MsalSingleton();
    }

    return MsalSingleton.instance;
  }
}
