import { useIsAuthenticated } from "@azure/msal-react";
import { getRolesByAzureGuidAsync } from "@bu/lib-services";
import { useQuery } from "@tanstack/react-query";

export const useRoleListQuery = () => {
  const isAuthenticated = useIsAuthenticated();
  const queryKey = ["roleList"];

  const query = useQuery({
    queryKey: queryKey,
    enabled: isAuthenticated,
    queryFn: getRolesByAzureGuidAsync,
  });

  return {
    roleList: query.data,

    isRoleListLoading: query.isLoading,
    isRoleListError: query.isError,
  };
};
