import {
  DeleteProfileApiResponse_v1,
  ProfileDto_v1,
  RoleDto_v1,
} from "@bu/lib-types";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const deleteProfileAsync = async (
  profile: ProfileDto_v1,
  role: RoleDto_v1
) => {
  const client = await apiClientFactory.buildWithAuthAsync(ApiTypes.Identity, [
    "tasks.read",
  ]);
  const config: AxiosRequestConfig = {
    method: "DELETE",
    url: `/api/v1/profile/${profile.guid}/role/${role.guid}`,
  };

  return client
    .request<DeleteProfileApiResponse_v1>(config)
    .then((response) => response.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess) {
        console.error("DeleteProfileAsync error.", data.errors);
        throw new Error("Error removing profile.");
      }

      // Return payload.
      return data.isSuccess;
    });
};
