import { Configuration, EventType } from "@azure/msal-browser";
import { getConfig } from "@bu/lib-config";
import { MsalSingleton } from "./msalSingleton";

export const getMsalConfig = (): Configuration => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const config = getConfig();

  return {
    auth: {
      clientId: config.AZURE_B2C_CLIENTID,
      authority: config.AZURE_B2C_AUTHORITY,
      knownAuthorities: [config.AZURE_B2C_KNOWNAUTHORITY1],
      redirectUri: config.AZURE_B2C_REDIRECTURI,
      postLogoutRedirectUri: config.AZURE_B2C_POSTLOGOUTREDIRECTURI,
    },
    cache: {
      cacheLocation: config.AZURE_B2C_CACHELOCATION,
      storeAuthStateInCookie:
        config.AZURE_B2C_STOREAUTHSTATEINCOOKIE === "true",
    },
  };
};

export const createMsalInstance = () => {
  const msalInstance = MsalSingleton.getInstance();
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  msalInstance
    .handleRedirectPromise()
    .then(function (response) {
      console.log("msal success", response);
    })
    .catch((error) => {
      console.log("msal error", error);
    });

  msalInstance.addEventCallback((event) => {
    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event?.payload
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const account = (event.payload as any).account;
      msalInstance.setActiveAccount(account);
    }
  });

  return msalInstance;
};
