import { useIsAuthenticated } from "@azure/msal-react";
import {
  deleteUserAsync,
  getUserByAzureGuidAsync,
  insertUserAsync,
  updateUserAsync,
} from "@bu/lib-services";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const useUserQuery = () => {
  const queryKey = ["user"];
  const queryClient = useQueryClient();
  const isAuthenticated = useIsAuthenticated();

  const query = useQuery({
    queryKey: queryKey,
    queryFn: getUserByAzureGuidAsync,
    enabled: isAuthenticated,
  });

  const insertMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: insertUserAsync,
  });

  const updateMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: updateUserAsync,
  });

  const deleteMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: deleteUserAsync,
    onSuccess: () => {
      queryClient.setQueryData(queryKey, undefined);
    },
  });

  return {
    user: query.data,

    insertUserAsync: insertMutation.mutateAsync,
    updateUserAsync: updateMutation.mutateAsync,
    deleteUserAsync: deleteMutation.mutateAsync,

    isUserLoading: query.isLoading,
    isUserError: query.isError,
  };
};
