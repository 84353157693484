import {
  SubscriptionDto_v1,
  UpdateSubscriptionApiResponse_v1,
  UpdateSubscriptionRequest_v1,
} from "@bu/lib-types";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const updateSubscriptionAsync = async (
  subscription: SubscriptionDto_v1
) => {
  const client = await apiClientFactory.buildWithAuthAsync(ApiTypes.Identity, [
    "tasks.read",
  ]);
  const config: AxiosRequestConfig<UpdateSubscriptionRequest_v1> = {
    data: {
      subscription: subscription,
    },
    method: "PATCH",
    url: `/api/v1/subscription/${subscription.guid}`,
  };

  return client
    .request<UpdateSubscriptionApiResponse_v1>(config)
    .then((res) => res.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess) {
        console.error("UpdateSubscriptionAsync error.", data.errors);
        throw new Error("Error updating subscription.");
      }

      // Return payload.
      return data.isSuccess;
    });
};
