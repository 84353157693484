import {
  BaseTrackDto_v1,
  InsertTrackApiResponse_v1,
  InsertTrackRequest_v1,
} from "@bu/lib-types";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const insertTrackAsync = async (
  releaseGuid: string,
  track: BaseTrackDto_v1
) => {
  const client = await apiClientFactory.buildWithAuthAsync(ApiTypes.Music, [
    "tasks.read",
  ]);
  const config: AxiosRequestConfig<InsertTrackRequest_v1> = {
    data: {
      track: track,
    },
    method: "POST",
    url: `/api/v1/track/release/${releaseGuid}`,
  };

  return client
    .request<InsertTrackApiResponse_v1>(config)
    .then((response) => response.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess || !data.payload) {
        console.error("InsertTrackAsync error.", data.errors);
        throw new Error("Error adding track.");
      }

      // Return payload.
      return data.payload.trackGuid;
    });
};
