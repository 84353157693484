import {
  deleteTrackAsync,
  getTrackByGuidAsync,
  insertTrackAsync,
  updateTrackAsync,
} from "@bu/lib-services";
import { BaseTrackDto_v1 } from "@bu/lib-types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export const useTrackQuery = (argTrackGuid?: string) => {
  const [_trackGuid, _setTrackGuid] = useState(argTrackGuid);
  const queryKey = ["track", _trackGuid];
  const queryClient = useQueryClient();

  // Updates internal GUID state if the GUID changes.
  useEffect(() => {
    if (_trackGuid === argTrackGuid) return;
    _setTrackGuid(argTrackGuid);
  }, [argTrackGuid, _trackGuid]);

  const query = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      if (!_trackGuid)
        return Promise.reject("BaseTrack must be inserted first");
      return getTrackByGuidAsync(_trackGuid);
    },
  });

  const insertMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (mutationArgs: {
      releaseGuid: string;
      track: BaseTrackDto_v1;
    }) => {
      if (!_trackGuid) throw new Error("Track cannot be inserted again");
      return insertTrackAsync(mutationArgs.releaseGuid, mutationArgs.track);
    },
    onSuccess: (newTrackGuid) => {
      _setTrackGuid(newTrackGuid);
      queryClient.setQueryData(queryKey, undefined);
    },
  });

  const updateMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: updateTrackAsync,
  });

  const deleteMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: () => {
      if (!_trackGuid)
        return Promise.reject("BaseTrack must be inserted first");
      return deleteTrackAsync(_trackGuid);
    },
    onSuccess: () => {
      _setTrackGuid(undefined);
      queryClient.setQueryData(queryKey, undefined);
    },
  });

  return {
    track: updateMutation.variables || query.data,

    insertTrackAsync: insertMutation.mutateAsync,
    updateTrackAsync: updateMutation.mutateAsync,
    deleteTrackAsync: deleteMutation.mutateAsync,

    isTrackLoading: query.isLoading,
    isTrackError: query.isError,
  };
};
