import { Area } from "react-easy-crop";

/** Crops an image based on the area provided using built in canvas tools.
 * @param image The image to crop.
 * @param area The area to crop the image to.
 * @returns The cropped image.
 **/
export const cropImage = (image: HTMLImageElement, area: Area) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  const x = area.x;
  const y = area.y;
  const width = area.width;
  const height = area.height;

  canvas.width = width;
  canvas.height = height;

  context?.drawImage(image, x, y, width, height, 0, 0, width, height);

  const croppedImage = new Image();
  croppedImage.src = canvas.toDataURL("image/jpg");

  return croppedImage;
};
