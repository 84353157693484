import { newGuid } from "@bu/lib-utils";
import { Lens, lens } from "@dhmk/zustand-lens";
import { BroadcastChannel } from "broadcast-channel";
import { Store } from "../../store";
import { RepeatMode } from "./repeatMode";

export interface MusicPlayerSlice {
  trackGuidList: string[];
  trackPosition: number;

  position: number;
  repeatMode: RepeatMode;
  isPlaying: boolean;
  isSeeking: boolean;
  isTabPlaying: boolean;
  isVisible: boolean;
  volume: number;

  /**
   * Plays the current track. Will set visibility state to true if it is not already.
   * @param broadcast Defaults to true. Broadcasts the state change to other tabs.
   */
  play: (broadcast?: boolean) => void;

  /**
   * Pauses the current track.
   * @param broadcast Defaults to true. Broadcasts the state change to other tabs.
   */
  pause: (broadcast?: boolean) => void;

  /**
   * Seeks the current track to a given position.
   * @param timeSeconds The position to set the track to, in seconds.
   * @param broadcast Defaults to true. Broadcasts the state change to other tabs.
   */
  seek: (timeSeconds: number, broadcast?: boolean) => void;

  /**
   * Closes the now playing drawer. Will stop playing music and reset position.
   * @param broadcast Defaults to true. Broadcasts the state change to other tabs.
   */
  close: (broadcast?: boolean) => void;

  /**
   * Sets the current track to the given track.
   * @param track The track to set.
   * @param broadcast Defaults to true. Broadcasts the state change to other tabs.
   */
  setTrackList: (trackGuidList: string[], broadcast?: boolean) => void;

  /**
   * Sets the current track to the given track.
   * @param track The track to set.
   * @param broadcast Defaults to true. Broadcasts the state change to other tabs.
   */
  setTrackPosition: (trackPosition: number, broadcast?: boolean) => void;

  /**
   * Sets the volume of the player.
   * @param volume The volume to set.
   * @param broadcast Defaults to true. Broadcasts the state change to other tabs.
   */
  setVolume: (volume: number, broadcast?: boolean) => void;

  /**
   * Toggles the repeat mode of the player.
   */
  toggleRepeatMode: (broadcast?: boolean) => void;
}

const musicPlayerSlice: Lens<MusicPlayerSlice, Store> = (set, get) => {
  const tabId = newGuid();
  const channel = new BroadcastChannel("bu-music-player");

  channel.onmessage = (event) => {
    // Ignore messages from yourself.
    if (event.tabId == tabId) {
      return;
    }

    // Sync state.
    set((state) => {
      return { ...state, ...event.state };
    });
  };

  return {
    trackGuidList: [],
    trackPosition: 0,

    position: 0,
    repeatMode: RepeatMode.One,
    isPlaying: false,
    isSeeking: false,
    isTabPlaying: false,
    isVisible: false,
    volume: 0.5,

    play: (broadcast = true) => {
      set((state) => {
        state.isPlaying = true;
        state.isVisible = true;
        state.isTabPlaying = true;
      });

      broadcast &&
        channel.postMessage({
          tabId,
          state: {
            isPlaying: true,
            isVisible: true,
            isTabPlaying: false,
          },
        });
    },

    pause: (broadcast = true) => {
      set((state) => {
        state.isPlaying = false;
        state.isVisible = true;
        state.isTabPlaying = true;
      });

      broadcast &&
        channel.postMessage({
          tabId,
          state: {
            isPlaying: false,
            isVisible: true,
            isTabPlaying: false,
          },
        });
    },

    seek: (position: number, broadcast = true) => {
      set((state) => {
        state.position = position;
      });

      broadcast &&
        channel.postMessage({
          tabId,
          state: {
            position: position,
          },
        });
    },

    close: (broadcast = true) => {
      set((state) => {
        state.isVisible = false;
        state.isPlaying = false;
        state.isTabPlaying = false;
        state.position = 0;
      });

      broadcast &&
        channel.postMessage({
          tabId,
          state: {
            isVisible: false,
            isPlaying: false,
            isTabPlaying: false,
            position: 0,
          },
        });
    },

    setTrackList: (trackGuidList: string[], broadcast = true) => {
      set((state) => {
        state.trackGuidList = trackGuidList;
        state.trackPosition = 0;

        state.position = 0;
        state.isVisible = true;
        state.isTabPlaying = true;
      });

      broadcast &&
        channel.postMessage({
          tabId,
          state: {
            trackGuidList,
            trackPosition: 0,

            position: 0,
            isVisible: true,
            isTabPlaying: false,
          },
        });
    },

    setTrackPosition: (trackPosition: number, broadcast = true) => {
      set((state) => {
        state.trackPosition = trackPosition;

        state.position = 0;
        state.isPlaying = true;
        state.isVisible = true;
        state.isTabPlaying = true;
      });

      broadcast &&
        channel.postMessage({
          tabId,
          state: {
            trackPosition,

            position: 0,
            isPlaying: true,
            isVisible: true,
            isTabPlaying: false,
          },
        });
    },

    setVolume: (volume: number, broadcast = true) => {
      set((state) => {
        state.volume = volume;
        state.isTabPlaying = true;
      });

      broadcast &&
        channel.postMessage({
          tabId,
          state: {
            volume,
            isTabPlaying: false,
          },
        });
    },

    toggleRepeatMode: (broadcast = true) => {
      let newMode = get().repeatMode;
      switch (newMode) {
        case RepeatMode.None:
          newMode = RepeatMode.All;
          break;
        case RepeatMode.All:
          newMode = RepeatMode.One;
          break;
        case RepeatMode.One:
          newMode = RepeatMode.None;
          break;
      }

      set((state) => {
        state.repeatMode = newMode;
        state.isTabPlaying = true;
      });

      broadcast &&
        channel.postMessage({
          tabId,
          state: { repeatMode: newMode, isTabPlaying: false },
        });
    },
  };
};

export const musicPlayerLens = lens(musicPlayerSlice);
