import { withLenses } from "@dhmk/zustand-lens";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

import { alertLens, AlertSlice } from "./slices/alert";
import { musicPlayerLens, MusicPlayerSlice } from "./slices/musicPlayer";
import { trackLens, TrackSlice } from "./slices/track";

export interface Store {
  alert: AlertSlice;
  musicPlayer: MusicPlayerSlice;
  track: TrackSlice;
}

const store = {
  alert: alertLens,
  musicPlayer: musicPlayerLens,
  track: trackLens,
};

export const useStore = create<Store>()(immer(withLenses(store)));

mountStoreDevtool("store", useStore);
