import { useIsAuthenticated } from "@azure/msal-react";
import {
  deleteSocialLinkAsync,
  getSocialLinkByGuidAsync,
  insertSocialLinkAsync,
  updateSocialLinkAsync,
} from "@bu/lib-services";
import { BaseSocialLinkDto_v1, SocialLinkDto_v1 } from "@bu/lib-types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";

/**
 * Queries a social link by socialLinkGuid.
 * @param socialLinkGuid (Optional) The social link GUID to query. If not provided, the social link will be a baseSocialLink and must be inserted first.
 * @returns
 */
export const useSocialLinkQuery = (socialLinkGuid?: string) => {
  const [_socialLinkGuid, _setSocialLinkGuid] = useState(socialLinkGuid);
  const queryKey = ["socialLink", _socialLinkGuid];
  const isAuthenticated = useIsAuthenticated();
  const queryClient = useQueryClient();

  // Updates internal GUID state if the GUID changes.
  useEffect(() => {
    if (_socialLinkGuid === socialLinkGuid) return;
    _setSocialLinkGuid(socialLinkGuid);
  }, [socialLinkGuid, _socialLinkGuid]);

  const query = useQuery({
    queryFn: () => {
      if (!_socialLinkGuid)
        throw new Error("BaseSocialLink must be inserted first");
      return getSocialLinkByGuidAsync(_socialLinkGuid);
    },
    queryKey: queryKey,
    enabled: isAuthenticated,
  });

  const insertMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (mutationArgs: {
      profileGuid: string;
      baseSocialLink: BaseSocialLinkDto_v1;
    }) => {
      if (_socialLinkGuid)
        throw new Error("SocialLink cannot be inserted again");
      return insertSocialLinkAsync(
        mutationArgs.profileGuid,
        mutationArgs.baseSocialLink
      );
    },
    onSuccess: (newSocialLinkGuid) => {
      _setSocialLinkGuid(newSocialLinkGuid);
    },
  });

  const updateMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (mutationArgs: {
      profileGuid: string;
      socialLink: SocialLinkDto_v1;
    }) => {
      if (!_socialLinkGuid)
        throw new Error("BaseSocialLink must be inserted first");
      return updateSocialLinkAsync(
        mutationArgs.profileGuid,
        mutationArgs.socialLink
      );
    },
  });

  const deleteMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: () => {
      if (!_socialLinkGuid)
        throw new Error("BaseSocialLink must be inserted first");
      return deleteSocialLinkAsync(_socialLinkGuid);
    },
    onSuccess: () => {
      _setSocialLinkGuid(undefined);
      queryClient.setQueryData(queryKey, undefined);
    },
  });

  return {
    socialLink: query.data as SocialLinkDto_v1,

    insertSocialLinkAsync: insertMutation.mutateAsync,
    updateSocialLinkAsync: updateMutation.mutateAsync,
    deleteSocialLinkAsync: deleteMutation.mutateAsync,

    isSocialLinkLoading: query.isLoading,
    isSocialLinkError: query.isError,
  };
};
