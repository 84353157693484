import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { palette } from "../palette";
import { themeBase } from "./themeBase";

export const themeDark = deepmerge(
  themeBase,
  createTheme({
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          "#layoutAside": {
            backgroundColor: "#191919",
            borderRight: "solid #666666 1px",
          },
          "#layoutHeader": {
            backgroundColor: "#111111",
            borderBottom: "solid #666666 1px",
          },
          "#layoutMain": { backgroundColor: "#000000" },
        },
      },
    },
    palette: {
      mode: "dark",

      primary: {
        main: palette.B600,
        light: "#e3f2fd",
        dark: "#42a5f5",
        contrastText: "rgba(0, 0, 0, 0.87)",
      },
      secondary: {
        main: palette.SG500, // MUI Default: "#c293d8",
        light: "#f3e5f5",
        dark: "#ab47bc",
        contrastText: "rgba(0, 0, 0, 0.87)",
      },
      error: {
        main: "#f44336",
        light: "#e57373",
        dark: "#d32f2f",
        contrastText: "#fff",
      },
      warning: {
        main: "#ffa726",
        light: "#ffb74d",
        dark: "#f57c00",
        contrastText: "rgba(0, 0, 0, 0.87)",
      },
      info: {
        main: "#29b6f6",
        light: "#4fc3f7",
        dark: "#0288d1",
        contrastText: "rgba(0, 0, 0, 0.87)",
      },
      success: {
        main: "#66bb6a",
        light: "#81c784",
        dark: "#388e3c",
        contrastText: "rgba(0, 0, 0, 0.87)",
      },

      text: {
        primary: "#FFF",
        secondary: "rgba(255, 255, 255, 0.7)",
        disabled: "rgba(255, 255, 255, 0.5)",
      },
      divider: "rgba(255, 255, 255, 0.12)",
      background: {
        default: "#000000",
        paper: "#191919",
      },
      action: {
        active: "rgba(0, 0, 0, 0.54)",
        hover: "rgba(0, 0, 0, 0.04)",
        hoverOpacity: 0.04,
        selected: "rgba(0, 0, 0, 0.08)",
        selectedOpacity: 0.08,
        disabled: "rgba(0, 0, 0, 0.26)",
        disabledBackground: "rgba(0, 0, 0, 0.12)",
        disabledOpacity: 0.38,
        focus: "rgba(0, 0, 0, 0.12)",
        focusOpacity: 0.12,
        activatedOpacity: 0.12,
      },
    },
  })
);
