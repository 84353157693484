import { DeleteReleaseApiResponse_v1 } from "@bu/lib-types";
import { AxiosRequestConfig } from "axios";
import { apiClientFactory, ApiTypes } from "../../apiClientFactory";

export const deleteReleaseByGuidAsync = async (releaseGuid: string) => {
  const client = await apiClientFactory.buildWithAuthAsync(ApiTypes.Music, [
    "tasks.read",
  ]);
  const config: AxiosRequestConfig = {
    method: "DELETE",
    url: `/api/v1/release/${releaseGuid}`,
  };

  return client
    .request<DeleteReleaseApiResponse_v1>(config)
    .then((response) => response.data.isSuccess);
};
