import { useIsAuthenticated } from "@azure/msal-react";
import {
  deleteUserAsync,
  getUserByAzureGuidAsync,
  insertUserAsync,
  updateUserAsync,
} from "@bu/lib-services";
import { UserDto_v1 } from "@bu/lib-types";
import { useMutation, useQuery } from "@tanstack/react-query";

export const useSelfQuery = () => {
  const isAuthenticated = useIsAuthenticated();
  const queryKey = ["self"];

  const query = useQuery({
    queryKey: queryKey,
    enabled: isAuthenticated,
    queryFn: getUserByAzureGuidAsync,
  });

  const updateQuery = useMutation({
    mutationFn: (userData: UserDto_v1) => {
      return updateUserAsync(userData);
    },
  });

  const deleteQuery = useMutation({
    mutationFn: deleteUserAsync,
  });

  const insertQuery = useMutation({
    mutationFn: insertUserAsync,
  });

  return {
    self: query.data,

    insertSelfAsync: insertQuery.mutateAsync,
    updateSelfAsync: updateQuery.mutateAsync,
    deleteSelfAsync: deleteQuery.mutateAsync,

    isSelfLoading: query.isLoading,
    isSelfError: query.isError,
  };
};
