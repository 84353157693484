import {
  GetPersonalStoriesPayload,
  GetPersonalStoriesResponse,
} from "@bu/lib-types";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const getPersonalStoriesAsync = async (
  payload: GetPersonalStoriesPayload
) => {
  const client = await apiClientFactory.buildAsync(ApiTypes.Mobile);

  const urlParams = new URLSearchParams();
  urlParams.append("page", payload.page.toString());
  if (payload.albumTracksLimit) {
    urlParams.append("albumTracksLimit", payload.albumTracksLimit.toString());
  }

  const config: AxiosRequestConfig = {
    method: "GET",
    url: `/api/v1/stories?${urlParams.toString()}`,
  };

  return client.request<GetPersonalStoriesResponse>(config).then((response) => {
    return response.data;
  });
};

