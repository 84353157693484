import { Lens, lens } from "@dhmk/zustand-lens";
import { Store } from "../../store";
import { TrackSliceEntry } from "./trackSliceEntry";

export interface TrackSlice {
  tracks: TrackSliceEntry[];

  setTracks: (tracks: TrackSliceEntry[]) => void;
  insertTrack: (release: TrackSliceEntry) => void;
  insertTracks: (release: TrackSliceEntry[]) => void;
  updateTrack: (release: TrackSliceEntry) => void;
  deleteTrack: (release: TrackSliceEntry) => void;
}

const trackSlice: Lens<TrackSlice, Store> = (set, get) => {
  return {
    tracks: [] as TrackSliceEntry[],

    setTracks: (tracks: TrackSliceEntry[]) => {
      set((state) => {
        state.tracks = tracks;
      });
    },

    insertTrack: (track: TrackSliceEntry) => {
      set((state) => {
        state.tracks.push({ ...track });
      });
    },

    insertTracks: (tracks: TrackSliceEntry[]) => {
      set((state) => {
        state.tracks.push(...tracks);
      });
    },

    updateTrack: (track: TrackSliceEntry) => {
      set((state) => {
        state.tracks = state.tracks.map((r) =>
          r.guid === track.guid ? { ...track } : r
        );
      });
    },

    deleteTrack: (track: TrackSliceEntry) => {
      set((state) => {
        state.tracks = state.tracks.filter((r) => r.guid !== track.guid);
      });
    },
  };
};

export const trackLens = lens(trackSlice);
